import { Expression, GraphingCalculator } from 'desmos-react';
import Guide from './Guide';
import './main.css';

const App = () => (
  <>
    <div className={'contianer max-w-6xl mx-auto p-6'}>
      <h1 className={'text-6xl font-bold'}>Graphwar Graph Guide</h1>
      <h2 className={'text-2xl font-semibold mt-3'}>Resource by Kyle Nguyen</h2>
      <h2 className={'text-2xl font-semibold'}>
        Original equations from hueyfragic's{' '}
        <a
          className={'text-blue-500 underline'}
          href={'https://www.youtube.com/watch?v=EHuQe7SKwkA'}
        >
          meta video
        </a>
        .
      </h2>
      <h2 className={'text-2xl font-semibold'}>
        Graph embeds included with permission from Desmos
      </h2>
      <Guide
        title="Step k/(1+exp(-a*(x+c)))"
        credit="vertcli"
        expression="k/(1+\exp(-a \times (x+c)))"
      >
        <Expression id="k" latex="k=5" sliderBounds={{ min: '-15', max: '15', step: '1' }} />
        <Expression id="a" latex="a=10" sliderBounds={{ min: '-50', max: '50', step: '1' }} />
        <Expression id="c" latex="c=0" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
      </Guide>
      <Guide title="Spike k/(1+(a*(x+c))^2)" credit="vertcli" expression="k/(1+(a \times (x+c))^2)">
        <Expression id="k" latex="k=5" sliderBounds={{ min: '-15', max: '15', step: '1' }} />
        <Expression id="a" latex="a=10" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
        <Expression id="c" latex="c=0" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
      </Guide>
      <Guide
        title="Double Abs a*(abs(x+b)-abs(x+c))"
        credit="vertcli"
        expression="a \times (\left|x+b\right|-\left|x+c\right|)"
      >
        <Expression id="a" latex="a=1" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
        <Expression id="b" latex="b=10" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
        <Expression id="c" latex="c=0" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
      </Guide>
      <Guide
        title="Sin d*sin(kx)/(1+exp(-a*(x+c)))"
        credit="hueyfragic"
        expression="d \times \sin(kx)/(1+\exp(-a \times (x+c)))"
      >
        <Expression id="a" latex="a=5" sliderBounds={{ min: '-10', max: '10', step: '1' }} />
        <Expression id="k" latex="k=3" sliderBounds={{ min: '-10', max: '10', step: '1' }} />
        <Expression id="d" latex="d=5" sliderBounds={{ min: '-5', max: '5', step: '1' }} />
        <Expression id="c" latex="c=0" sliderBounds={{ min: '-25', max: '25', step: '1' }} />
      </Guide>
    </div>
  </>
);

export default App;
