import { Expression, GraphingCalculator } from 'desmos-react';
import ReactKatex from '@pkasila/react-katex';
import { FunctionComponent, useEffect, useRef } from 'react';

type GuideProps = {
  title: string;
  credit: string;
  expression: string;
  children: React.ReactNode;
};

const Guide: FunctionComponent<GuideProps> = (props) => {
  const ref = useRef<Desmos.Calculator>(null);

  useEffect(() => {
    const calculator = ref.current;
    if (calculator == null) return;
    calculator.setMathBounds({ left: -25, right: 25, bottom: -15, top: 15 });
  }, [ref.current]);

  return (
    <>
      <div className={'px-4 py-4 my-8 rounded-xl shadow-lg bg-gray-200'}>
        <h1 className={'text-4xl font-semibold'}>{props.title}</h1>
        <h2 className={'text-xl font-semibold'}>Original Credit: {props.credit}</h2>
        <div className={'bg-gray-100 p-4 mt-4 rounded'}>
          <ReactKatex children={`$$${props.expression}$$`} />
        </div>
        <GraphingCalculator
          attributes={{ className: 'calculator h-96 my-4' }}
          fontSize={16}
          ref={ref}
        >
          <Expression id="expression" latex={props.expression} />
          {props.children}
        </GraphingCalculator>
      </div>
    </>
  );
};

export default Guide;
